import React from 'react';
import { Box, Button, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';

interface LLMModel {
  name: string;
  description: string;
}

interface ReviewSectionProps {
  models: LLMModel[];
  selectedModel: LLMModel | null;
  handleModelChange: (event: SelectChangeEvent<string>) => void;
  handleSubmitExam: () => void;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ models, selectedModel, handleModelChange, handleSubmitExam }) => (
  <Box display="flex" flexDirection="column" alignItems="flex-start">

    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>

      {/* モデル選択 */}
      {/* <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
        <InputLabel id="model-select-label">モデル選択</InputLabel>
        <Select
          labelId="model-select-label"
          id="model-select"
          value={selectedModel?.name || ''} // selectedModelの名前を表示
          onChange={handleModelChange}
          label="モデル選択"
        >
          {models.map((model) => (
            <MenuItem key={model.name} value={model.name}>
              {model.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

      {/* 審査実行ボタン */}
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Button
          variant="contained"
          onClick={handleSubmitExam}
          sx={{
            width: '240px',
            height: '54px',
            backgroundColor: '#5135dc',
          }}
        >
          <Typography
            variant="button"
            color="inherit"
            fontSize="16px"
          >
            審査する
          </Typography>
        </Button>
      </Box>
    </Box>

    {/* モデルの説明 */}
    {/* {selectedModel && (
      <Box marginTop={2} marginRight={2}>
        <Typography variant="h6">モデルの説明:</Typography>
        <Typography variant="body1">{selectedModel.description}</Typography>
      </Box>
    )} */}
  </Box>
);

export default ReviewSection;
