import React, { useState, useEffect } from 'react';
import axios from "axios";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { auth, signOut, signInWithEmailAndPassword } from '../firebase';
import SignInForm from '../components/SignInForm';
import ReviewModal from '../components/ReviewModal';
import UserListModal from '../components/UserListModal';
import DashboardIcon from './DashboardIcon';
import logo from '../assets/logo.svg';

interface HeaderProps {
    user: any;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    setSnackbarState: (state: { open: boolean, message: string, color: string }) => void;
    refreshHeader: boolean;
}

const Header: React.FC<HeaderProps> = ({
    user,
    loading,
    setLoading,
    setSnackbarState,
    refreshHeader
}) => {
    const [userGroup, setUserGroup] = useState<any>(null);  // 所属組織
    const [userReviews, setUserReviews] = useState<any[]>([]);
    const [groupReviewStats, setGroupReviewStats] = useState<{ reviewCount: number; totalCost: number } | null>(null);
    const [userReviewStats, setUserReviewStats] = useState<{ reviewCount: number; totalCost: number } | null>(null); // 審査回数とコスト
    const [userList, setUserList] = useState<any[]>([]);
    const [userListError, setUserListError] = useState<string | null>(null);
    const [userListModalOpen, setUserListModalOpen] = useState(false);
    const [authSnackbarMessage, setAuthSnackbarMessage] = useState<string>('');
    const [authSnackbarOpen, setAuthSnackbarOpen] = useState<boolean>(false);
    const [showSignInForm, setShowSignInForm] = useState<boolean>(false);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);
    const [statsError, setStatsError] = useState<string | null>(null); // エラー管理

    useEffect(() => {
        if (user) {
            fetchUserGroup(user);
            fetchUserReviewStats(user);
            fetchGroupReviewStats(user);
        }
    }, [user, refreshHeader]); // Trigger when `user` changes

    const reviewCountStyle = {
        display: 'flex',
        padding: '8px 16px',         // var(--spacing-2016-px, 16px) の代わり
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '4px',
        border: '1px solid #DAD4F7',
        backgroundColor: '#FFF',
    };

    const handleOpenReviewModal = async () => {
        setLoading(true);
        try {
            const idToken = await user.getIdToken(); // Firebaseの認証トークンを取得
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/user_reviews`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            });

            if (response.status >= 200 && response.status < 300) {
                setUserReviews(response.data.reviews);
                setReviewModalOpen(true);
                //setSnackbarState({ open: true, message: 'ユーザの審査履歴の取得に成功しました', color: '#43a047' });
            } else {
                console.error("Failed to fetch user reviews.");
            }
        } catch (error) {
            console.error("Error fetching user reviews:", error);
            setSnackbarState({ open: true, message: 'ユーザの審査履歴の取得に失敗しました', color: '#d32f2f' });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseReviewModal = () => {
        setReviewModalOpen(false);
    };

    const handleOpenSignInForm = () => {
        setShowSignInForm(true);
    };

    const handleCloseSignInForm = () => {
        setShowSignInForm(false);
    };


    // グループごとの審査統計を取得する関数
    const fetchGroupReviewStats = async (user: any) => {
        setStatsError(null);

        try {
            const idToken = await user.getIdToken();
            const apiEndpoint = `${process.env.REACT_APP_BACKEND_API}/group_stats`
            const response = await axios.get(apiEndpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${idToken}`,
                }
            });

            if (response.status >= 200 && response.status < 300) {
                const { review_count, total_cost } = response.data;
                setGroupReviewStats({ reviewCount: review_count, totalCost: total_cost });
                //setSnackbarState({ open: true, message: 'グループの統計の取得に成功しました。', color: '#43a047' });
            } else {
                setStatsError('グループ統計の取得に失敗しました。');
                setSnackbarState({ open: true, message: 'グループの統計の取得に失敗しました。', color: '#d32f2f' });
            }
        } catch (error) {
            console.error('Failed to fetch group stats:', error);
            setStatsError('グループ統計の取得中にエラーが発生しました。');
        }
    };


    const fetchGroupUsers = async () => {
        setLoading(true);
        setUserListError(null);
        try {
            const idToken = await user.getIdToken(); // Firebaseの認証トークンを取得
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/group_users`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            });

            if (response.status >= 200 && response.status < 300) {
                setUserList(response.data.users); // ユーザリストをセット
                setUserListModalOpen(true); // モーダルを開く
            } else {
                console.error("Failed to fetch group users.");
            }
        } catch (error) {
            console.error("Error fetching group users:", error);
        } finally {
            setLoading(false);
        }
    };

    // ユーザーごとの審査統計を取得する関数
    const fetchUserReviewStats = async (user: any) => {
        setStatsError(null);

        try {
            const idToken = await user.getIdToken();
            const apiEndpoint = `${process.env.REACT_APP_BACKEND_API}/user_stats`;
            const response = await axios.get(apiEndpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${idToken}`,
                }
            });

            if (response.status >= 200 && response.status < 300) {
                const { review_count, total_cost } = response.data;
                setUserReviewStats({ reviewCount: review_count, totalCost: total_cost });
                //setSnackbarState({ open: true, message: 'ユーザの統計の取得に成功しました。', color: '#43a047' });
                console.log("uset's review_count: " + review_count + " total_cost: total_cost")
            } else {
                setStatsError('審査統計の取得に失敗しました。');
                setSnackbarState({ open: true, message: 'ユーザの統計の取得に失敗しました。', color: '#d32f2f' });
            }
        } catch (error) {
            console.error('Failed to fetch user stats:', error);
            setStatsError('ユーザの統計の取得中にエラーが発生しました。');
            setSnackbarState({ open: true, message: 'ユーザの統計の取得中にエラーが発生しました。', color: '#d32f2f' });
        }
    };

    const fetchUserGroup = async (user: any) => {
        try {
            const idToken = await user.getIdToken(); // Firebaseユーザーのトークンを取得
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/group_info`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${idToken}` // 認証ヘッダー
                    }
                });

            if (response.status >= 200 && response.status < 300) {
                const group = response.data.group;
                setUserGroup(group);
                //setSnackbarState({ open: true, message: 'グループ情報を取得しました', color: '#43a047' });
                fetchGroupReviewStats(user);
            } else {
                //setUserGroup(null);
                setSnackbarState({ open: true, message: 'グループ情報を取得できませんでした', color: '#d32f2f' });
            }
        } catch (error) {
            console.error('Failed to fetch group info:', error);
            setSnackbarState({ open: true, message: 'グループ情報の取得に失敗しました', color: '#d32f2f' });
        }
    };


    const handleSignIn = async (email: string, password: string) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setAuthSnackbarMessage('サインインしました');
            setAuthSnackbarOpen(true);
            setShowSignInForm(false);
        } catch (error) {
            console.error('サインインに失敗しました', error);
            setAuthSnackbarMessage('サインインに失敗しました');
            setAuthSnackbarOpen(true);
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            setAuthSnackbarMessage('サインアウトしました');
            setAuthSnackbarOpen(true);
            setUserReviewStats(null); // サインアウト時に統計をクリア
            setGroupReviewStats(null)
        } catch (error) {
            console.error('サインアウトに失敗しました', error);
            setAuthSnackbarMessage('サインアウトに失敗しました');
            setAuthSnackbarOpen(true);
        }
    };

    return (
        <Box sx={{ flexGrow: 1, width: '100vw', overflow: 'hidden' }}>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: '#FFF',       // 背景色
                    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.10)',  // 影
                    height: '64px',                // 固定高さ
                    margin: '0px',
                }}>
                <Toolbar sx={{
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    justifyContent: 'space-between', // 要素間のスペース
                    alignItems: 'center',          // 縦方向の中央揃え
                    height: '100%',
                    width: '100vw',                 // AppBar内の幅を指定
                }}>
                    {/* ロゴ */}
                    <Box sx={{ flexShrink: 0 }}>
                        <img src={logo} alt="Logo" style={{ width: '99.556px', height: '28px' }} />
                    </Box>

                    {/* 右側の要素 */}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '40px' }}>
                        {user ? (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '0 8px',
                                width: 'auto',
                            }}>
                                {/* User Review Stats */}
                                <Box sx={reviewCountStyle}>
                                    {statsError ? (
                                        <Typography variant="body2" sx={{ color: 'error.main' }}>{statsError}</Typography>
                                    ) : userReviewStats ? (
                                        <Box>
                                            <Typography variant="body2" sx={{ color: '#232323' }}>
                                                あなたの審査回数: <Box
                                                    component="span"
                                                    sx={{
                                                        color: '#5135DC',
                                                        fontFamily: 'Albert Sans',
                                                        fontSize: '20px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 700,
                                                        lineHeight: '140%', // 28px
                                                    }}
                                                >
                                                    {userReviewStats.reviewCount}
                                                </Box>回
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography variant="body2" sx={{ color: '#232323' }}>審査統計データがありません。</Typography>
                                    )}
                                </Box>

                                {/* User Group Info */}
                                {userGroup && (
                                    <Box
                                        display="flex" alignItems="center" sx={{ ...reviewCountStyle, marginLeft: 2 }}
                                    >
                                        {statsError ? (
                                            <Typography variant="body2" color="error">{statsError}</Typography>
                                        ) : groupReviewStats ? (
                                            <Box>
                                                <Typography variant="body2" color="black">
                                                    グループの審査回数: <Box
                                                        component="span"
                                                        sx={{
                                                            color: '#5135DC',
                                                            fontFamily: 'Albert Sans',
                                                            fontSize: '20px',
                                                            fontStyle: 'normal',
                                                            fontWeight: 700,
                                                            lineHeight: '140%', // 28px
                                                        }}
                                                    >
                                                        {groupReviewStats.reviewCount}
                                                    </Box> / {userGroup.limit_count}回
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Typography variant="body2" color="black">審査統計データがありません。</Typography>
                                        )}
                                    </Box>
                                )}
                                <Box
                                    display="flex" alignItems="center" sx={{ marginLeft: '24px' }}
                                >
                                    <DashboardIcon
                                        handleOpenHistory={handleOpenReviewModal}
                                        handleSignOut={handleSignOut}
                                        handleGetGroupUsers={fetchGroupUsers}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Button
                                sx={{
                                    padding: '6px 24px',           // ボタンのパディング
                                    width: '140px',                // ボタンの固定幅
                                    textAlign: 'center',           // テキストを中央に配置
                                }}
                                onClick={handleOpenSignInForm}
                            >
                                <Typography variant="button" color="black">
                                    サインイン
                                </Typography>
                            </Button>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>

            <SignInForm open={showSignInForm} onClose={handleCloseSignInForm} onSignIn={handleSignIn} />

            {userGroup ? (
                <Box>
                    <ReviewModal
                        open={reviewModalOpen}
                        onClose={handleCloseReviewModal}
                        reviews={userReviews}
                    />
                    <UserListModal
                        open={userListModalOpen}
                        onClose={() => setUserListModalOpen(false)}
                        userList={userList}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

export default Header;
