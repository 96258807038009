// src/utils/resultUtils.ts

export const getColorForScore = (score: number) => {
    const colors = [
      'rgb(250,227,227)',
      'rgb(245,200,200)',
      'rgb(240,180,180)',
      'rgb(235,150,150)',
      'rgb(230,120,120)',
      'rgb(225,100,100)',
      'rgb(220,80,80)',
      'rgb(215,60,60)',
      'rgb(210,40,40)',
      'rgb(200,20,20)',
    ];

    const index = Math.min(Math.floor(score * (colors.length - 1)), colors.length - 1);
    return colors[index];
  };

  export const getBorderForScore = (score: number) => {
    if (score <= 0.2) {
      return '1px solid #cbcbcb';
    }
    return 'none';
  };

  export const getTextColorForScore = (score: number) => {
    if (score <= 0.2) {
      return '#232323';
    }
    return '#ffffff';
  };
