import React, { useState, useRef, useEffect, useCallback } from 'react';
import './App.css';
import axios from "axios";
import { debounce } from 'lodash';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Backdrop,
  Snackbar,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { auth, onAuthStateChanged } from './firebase';
import QuestionList from './components/QuestionList';
import ResultDisplay from './components/ResultDisplay';
import PromptEditor from './components/PromptEditor';
import Header from './components/Header';
import Footer from './components/Footer'
import FileUpload from './components/FileUpload';
import useTaskStatus from './components/TaskStatus';  // Import the custom hook
import ContradictionSettings from './components/ContradictionSettings';
import DocumentUploadSection from './components/DocumentUploadSection';
import ReviewSection from './components/ReviewSection';
//import DeveloperTools from './components/DeveloperTools';


function App() {
  const researchPlanRef = useRef<{ getText: () => string; getFileName: () => string; setTextField: (text: string) => void }>(null);
  const consentFormRef = useRef<{ getText: () => string; getFileName: () => string; setTextField: (text: string) => void }>(null);
  const [user, setUser] = useState<any>(null);
  const [questions, setQuestions] = useState<string[]>([]);
  const [newQuestion, setNewQuestion] = useState<string>('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [result, setResult] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshHeader, setRefreshHeader] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string | null>(null); // Store the task_id from the backend
  const [openItemIds, setOpenItemIds] = useState<string[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<string>('元の順番');
  const [snackbarState, setSnackbarState] = useState<{ open: boolean, message: string, color: string }>({ open: false, message: '', color: '#43a047' });
  const [models, setModels] = useState<LLMModel[]>([]);
  const [selectedModel, setSelectedModel] = useState<LLMModel | null>(null); // State for selected model
  const [contradictThreshold, setContradictThreshold] = useState<number>(0.5);
  const [displayContradictThreshold, setDisplayContradictThreshold] = useState<number>(0.5);
  const [segmentSize, setSegmentSize] = useState<number>(25);
  const [useSegmentToSegment, setUseSegmentToSegment] = useState<boolean>(true);
  const [selectedDocumentReviewPrompt, setSelectedDocumentReviewPrompt] = useState<string>(''); // 書類審査用プロンプト
  const [selectedContradictionDetectionPrompt, setSelectedContradictionDetectionPrompt] = useState<string>(''); // 矛盾検知用プロンプト

  const inputRef = useRef<HTMLInputElement>(null);

  // 開発者用の、デフォルト質問リスト追加ロジックを走らせるなどのためのツールを表示するためのbool
  //const isDeveloper = true;

  interface LLMModel {
    name: string;
    description: string;
  }

  useEffect(() => {
    if (editingIndex !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingIndex]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/models`);
        setModels(response.data.models);
        setSelectedModel(response.data.models[1]);
      } catch (error) {
        console.error('Failed to fetch models:', error);
      }
    };

    fetchModels();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Albert Sans',
        'Zen Kaku Gothic New',
        'sans-serif',
      ].join(','),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            'html:lang(ja)': {
              fontFamily: 'Zen Kaku Gothic New, sans-serif',
            },
            'body': {
              fontFamily: 'Albert Sans, sans-serif',
            },
          },
        },
      },
    },
  });

  const handleModelChange = (event: SelectChangeEvent<string>) => {
    const selectedModelName = event.target.value as string; // Get selected model name
    const model = models.find((model) => model.name === selectedModelName); // Find the model object by name
    setSelectedModel(model || null); // Set the selected model object
  };


  const handleAddQuestion = () => {
    if (newQuestion.trim() !== '') {
      setQuestions([...questions, newQuestion]);
      setNewQuestion('');
    }
  };

  const handleDeleteQuestion = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleEditQuestion = (index: number) => {
    setEditingIndex(index);
  };

  const handleSaveQuestion = (index: number) => {
    setEditingIndex(null);
  };

  const handleQuestionChange = (index: number, newValue: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = newValue;
    setQuestions(updatedQuestions);
  };

  const handleUpload = async (file: File, setText: (text: string) => void) => {
    if (!file) return;

    if (!user) {
      console.error("User is not logged in.");
      setSnackbarState({ open: true, message: 'ログインしてください', color: '#d32f2f' });
      return;
    }

    setUploading(true);

    const apiEndpoint = `${process.env.REACT_APP_BACKEND_API}/application`;
    const formData = new FormData();
    formData.append('file', file);

    try {
      const idToken = await user.getIdToken();
      const response = await axios.post(
        apiEndpoint,
        formData,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const responseData = response.data;
        setText(responseData);
        setSnackbarState({ open: true, message: 'アップロードが成功しました', color: '#43a047' });
      } else {
        console.error('アップロードに失敗しました');
        setSnackbarState({ open: true, message: 'アップロードに失敗しました', color: '#d32f2f' });
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      setSnackbarState({ open: true, message: 'エラーが発生しました', color: '#d32f2f' });
    } finally {
      setUploading(false);
    }
  };

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseSegmentToSegment(event.target.value === 'segmentToSegment');
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleError = useCallback((message: string) => {
    // Actions to take on error or timeout
    setResult(null);
    setTaskId(null);
    setLoading(false);
    setSnackbarState({ open: true, message: '審査に失敗しました（' + message + ')', color: '#d32f2f' });
  }, []);

  const handleCompleteTask = useCallback(async (taskId: string) => {
    if (!user) {
      console.error("User is not logged in.");
      setSnackbarState({ open: true, message: 'ログインしてください', color: '#d32f2f' });
      return;
    }

    try {
      const apiEndpoint = `${process.env.REACT_APP_BACKEND_API}/get_review_by_id`;
      const idToken = await user.getIdToken();
      const response = await axios.get(apiEndpoint, {
        headers: {
          'Authorization': `Bearer ${idToken}`,  // Pass the idToken in the Authorization header
          'Content-Type': 'application/json',
        },
        params: {
          task_id: taskId,  // Pass task_id as a query parameter
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const responseData = response.data;
        console.log(responseData);
        setResult(responseData.review);
        setSnackbarState({ open: true, message: '審査が完了しました', color: '#43a047' });

        //const { user_review_count, user_total_cost, group_review_count, group_total_cost } = responseData; // 月毎の合計コストと審査回数
        // setUserReviewStats({
        //   reviewCount: user_review_count,
        //   totalCost: user_total_cost,
        // });
        // setGroupReviewStats({
        //   reviewCount: group_review_count,
        //   totalCost: group_total_cost
        // })
      } else {
        console.error('An error occurred on the server:', response.status);
        setResult(null);
        setLoading(false);
        setSnackbarState({ open: true, message: '審査に失敗しました（サーバーエラー）', color: '#d32f2f' });
      }

    } catch (error) {
      console.error('An error occurred during the request:', error);
      setResult(null);
      setSnackbarState({ open: true, message: 'エラーが発生しました（サーバーエラー）', color: '#d32f2f' });
    } finally {
      setRefreshHeader((prev) => !prev);
      setLoading(false);
    }
  }, [user]);

  const handleSubmitExam = async () => {
    if (!user) {
      console.error("User is not logged in.");
      setSnackbarState({ open: true, message: 'ログインしてください', color: '#d32f2f' });
      return;
    }

    // Get the text from the FileUpload component using the ref
    const researchPlanText = researchPlanRef.current?.getText();
    const researchPlanFileName = researchPlanRef.current?.getFileName();
    const consentFormText = consentFormRef.current?.getText();
    const consentFormFileName = consentFormRef.current?.getFileName();

    if (!researchPlanText || researchPlanText.length === 0) {
      console.error("No text");
      setSnackbarState({ open: true, message: '研究計画書が入力されていません', color: '#d32f2f' });
      return;
    }

    if (questions.length === 0) {
      console.error("No questions");
      setSnackbarState({ open: true, message: '質問リストがありません。', color: '#d32f2f' });
      return;
    }

    if (!selectedModel) {
      console.error("No model selected");
      setSnackbarState({ open: true, message: 'モデルが選択されていません', color: '#d32f2f' });
      return;
    }

    setLoading(true);

    try {
      const apiEndpoint = `${process.env.REACT_APP_BACKEND_API}/exam`;
      const idToken = await user.getIdToken();
      const requestBody = {
        FileName1: researchPlanFileName,
        FileContent1: researchPlanText,
        FileName2: consentFormFileName,
        FileContent2: consentFormText,
        questions: questions,
        model: selectedModel?.name || '',
        segment_size: segmentSize,
        useSegmentToSegment: useSegmentToSegment,
        //documentReviewPrompt: selectedDocumentReviewPrompt, // 書類審査用プロンプト
        //: selectedContradictionDetectionPrompt, // 矛盾検知用プロンプト
      };

      const response = await axios.post(apiEndpoint, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const responseData = response.data;
        console.log('Task ID:', responseData.task_id); // Log the task_id
        setTaskId(responseData.task_id); // Set taskId from the response

        //setResult(responseData);
        //setSnackbarState({ open: true, message: '審査が完了しました', color: '#43a047' });
        // ここで審査回数とコストを更新
        //const { user_review_count, user_total_cost, group_review_count, group_total_cost } = responseData; // 月毎の合計コストと審査回数
        // setUserReviewStats({
        //   reviewCount: user_review_count,
        //   totalCost: user_total_cost,
        // });
        // setGroupReviewStats({
        //   reviewCount: group_review_count,
        //   totalCost: group_total_cost
        // })
      } else {
        console.error('An error occurred on the server:', response.status);
        setResult(null);
        setLoading(false);
        setSnackbarState({ open: true, message: '審査に失敗しました', color: '#d32f2f' });
      }
    } catch (error) {
      console.error('An error occured during the request:', error);
      setResult(null);
      setLoading(false);
      setSnackbarState({ open: true, message: 'エラーが発生しました', color: '#d32f2f' });
    }
  };

  const handleToggle = (itemId: string) => {
    setOpenItemIds((prevOpenItemIds) =>
      prevOpenItemIds.includes(itemId)
        ? prevOpenItemIds.filter((id) => id !== itemId) // アイテムが既に開いていれば閉じる
        : [...prevOpenItemIds, itemId] // アイテムを追加して開く
    );
  };

  const updateDisplayContradictThreshold = debounce((value: number) => {
    setDisplayContradictThreshold(value);
  }, 300);


  // Use the custom hook to monitor task status
  const taskStatus = useTaskStatus(taskId, handleCompleteTask, handleError);

  return (
    <ThemeProvider theme={theme}>
      <Box className="App" sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', overflowX: 'hidden' }}>
        {/* ヘッダー */}
        <Header
          user={user}
          loading={loading}
          setLoading={setLoading}
          setSnackbarState={setSnackbarState}
          refreshHeader={refreshHeader}
        />
        {/* ボディとなる部分 */}
        <Container
          maxWidth={false}
          disableGutters={true}
          sx={{
            backgroundColor: '#F3F1FC',
            width: '100%',
            padding: '80px 120px',
            display: 'flex',
            gap: '80px',
            flexDirection: 'column',
          }}>
          {/* 書類のアップロード・確認・編集部分 */}
          <DocumentUploadSection
            researchPlanRef={researchPlanRef}
            consentFormRef={consentFormRef}
            handleUpload={handleUpload}
          />

          {/* 質問リストの部分 */}
          <QuestionList
            questions={questions}
            newQuestion={newQuestion}
            editingIndex={editingIndex}
            onAddQuestion={handleAddQuestion}
            onDeleteQuestion={handleDeleteQuestion}
            onEditQuestion={handleEditQuestion}
            onSaveQuestion={handleSaveQuestion}
            onQuestionChange={handleQuestionChange}
            onNewQuestionChange={setNewQuestion}
            setQuestions={setQuestions}
          />

          {/* 審査実行部分（設定、審査ボタン） */}
          <ReviewSection
            models={models}
            selectedModel={selectedModel}
            handleModelChange={handleModelChange}
            handleSubmitExam={handleSubmitExam}
          />

          {/* 審査結果部分 */}
          {result && (
            <ResultDisplay
              result={result}
              sortOrder={sortOrder}
              openItemIds={openItemIds}
              contradictThreshold={contradictThreshold}
              displayContradictThreshold={displayContradictThreshold}
              onSortOrderChange={setSortOrder}
              onToggle={handleToggle}
              onContradictThresholdChange={(e, value) => {
                setContradictThreshold(value as number);
                updateDisplayContradictThreshold(value as number);
              }}
              sortQuestions={(questions, orderMethod) => {
                if (!Array.isArray(questions)) {
                  // questions が配列でない場合は空の配列を返す
                  return [];
                }
                if (orderMethod === '得点が低い順') {
                  return [...questions].sort((a, b) => a.result.response.score - b.result.response.score);
                } else if (orderMethod === '得点が高い順') {
                  return [...questions].sort((a, b) => b.result.response.score - a.result.response.score);
                } else if (orderMethod === '元の順番' || !orderMethod) {
                  // 元の順番で配列のコピーを返す（変更を避けるため）
                  return [...questions];
                }
                return [...questions];
              }}
            />
          )}
        </Container>
        {/* フッター */}
        <Footer />

        {/* 常時表示でないコンポーネント */}
        <Backdrop open={loading || uploading} style={{ zIndex: 2000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={snackbarState.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarState.message}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          ContentProps={{
            style: { backgroundColor: snackbarState.color, color: '#fff' },
          }}
        />
      </Box>
    </ThemeProvider>
  );
}

export default App;
