import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

interface SignInFormProps {
  open: boolean;
  onClose: () => void;
  onSignIn: (email: string, password: string) => void;
}

const SignInForm: React.FC<SignInFormProps> = ({ open, onClose, onSignIn }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSignIn = () => {
    if (!email || !password) {
      setErrorMessage('メールアドレスとパスワードを入力してください');
      return;
    }
    onSignIn(email, password);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>サインイン</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="メールアドレス"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errorMessage && !email}
          helperText={!!errorMessage && !email ? errorMessage : ''}
        />
        <TextField
          margin="dense"
          id="password"
          label="パスワード"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errorMessage && !password}
          helperText={!!errorMessage && !password ? errorMessage : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
        <Button onClick={handleSignIn} color="primary">
          サインイン
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignInForm;