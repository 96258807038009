import React, { useState, useImperativeHandle, forwardRef, ReactNode } from 'react';
import { Typography, TextField, Box, Button } from '@mui/material';
import FileUploadHeader from './FileUploadHeader';
import uploadIcon from '../assets/uploadIcon.svg'

interface FileUploadProps {
  title?: string;
  label?: string;
  placeholder?: string;
  acceptedFileTypes?: string;
  rows?: number;
  onUpload: (file: File, updateTextField: (text: string) => void) => Promise<void>;
  icon?: ReactNode;
}

const FileUpload = forwardRef(({
  title = 'ファイルアップロード',
  label = '',
  placeholder = '',
  acceptedFileTypes = '.doc,.docx',
  rows = 30,
  onUpload,
  icon,
}: FileUploadProps, ref) => {
  const [text, setText] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false); // To manage drag state

  // Handle drag over event
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  // Handle drag leave event
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // Handle file drop event
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0]; // Get the dropped file
    handleFileChange(droppedFile); // Directly call handleFileChange with the dropped file
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  // Your existing handleFileChange function, slightly modified
  const handleFileChange = (file: File | null) => {
    if (file) {
      setFile(file);
      setUploading(true);
      onUpload(file, setText).finally(() => setUploading(false));
    }
  };

  useImperativeHandle(ref, () => ({
    getText: () => text,
    getFileName: () => file?.name || '',
    setTextField: (newText: string) => setText(newText),
  }));

  return (
    <Box
      sx={{
        borderColor: 'grey.300',
        marginY: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        backgroundColor: 'transparent',
        borderRadius: '8px'
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {/* 書類の種類名やアイコンなどの案内を含んだ、書類アップロードセクション内のヘッダ */}
      <FileUploadHeader title={title} icon={icon} />

      {/* Body Section */}
      {/* ファイルがない場合はアップロードについての案内 */}
      {!file ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '720px',
            gap: '16px',
            background: '#FFF',
            borderRadius: '8px',
            border: '1px solid #DAD4F7',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
            <Box sx={{ width: '24px', height: '24px' }}>
              <img src={uploadIcon} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  color: '#232323',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '22.4px', // 140% of font-size
                }}
              >ここにファイルをドロップ
              </Typography>
              <Typography
                sx={{
                  color: '#646464',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '19.6px', // 140% of font-size
                }}
              >（.Doc,.DOCXのみ）
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              color: '#232323',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '22.4px', // 140% of font-size
            }}
          >
            または
          </Typography>
          <label htmlFor={`upload-file-${title}`}>
            <input
              id={`upload-file-${title}`}
              accept={acceptedFileTypes}
              style={{ display: 'none' }}
              type="file"
              onChange={(e) => handleFileChange(e.target.files && e.target.files[0])}
            />
            <Button
              variant="outlined"
              sx={{
                display: 'inline-flex',
                padding: '8px 16px',
                gap: '8px',
                borderRadius: '4px',
                border: '1px solid #5F46DE',
                color: '#5F46DE',
                backgroundColor: '#FFF',
                '&:hover': { backgroundColor: '#E5E3FA' },
                '&:active': { backgroundColor: '#DAD4F7' },
              }}
              component="span"
            >
              ファイルを選択
            </Button>
          </label>
        </Box>
      ) : (
        // ファイルがある場合はファイル名、ファイル変更ボタン、ファイルの中身の表示
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            background: 'transparent',
          }}
        >
          {/* ファイル名表示と、変更ボタンの部分 */}
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            {/* ファイル名部分 */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px' }}>
              <Typography
                sx={{
                  color: '#646464',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '140%'
                }}
              >
                選択されたファイル：
              </Typography>
              <Typography
                sx={{
                  color: '#5F46DE',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '140%',
                }}
              >
                {file.name}
              </Typography>
            </Box>
            <label htmlFor={`upload-file-${title}`}>
              <input
                id={`upload-file-${title}`}
                accept={acceptedFileTypes}
                style={{ display: 'none' }}
                type="file"
                onChange={(e) => handleFileChange(e.target.files && e.target.files[0])}
              />
              <Button
                variant="outlined"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '4px 8px',
                  gap: '8px',
                  borderRadius: '4px',
                  border: '1px solid #5F46DE',
                  background: '#FFF',
                  color: '#5F46DE',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '19.6px',
                  '&:hover': {
                    backgroundColor: '#E5E3FA'
                  },
                  '&:active': {
                    backgroundColor: '#DAD4F7'
                  }
                }}
                component="span"
              >
                ファイルを変更
              </Button>
            </label>
          </Box>

          <TextField
            label={label}
            placeholder={placeholder}
            multiline
            fullWidth
            rows={rows}
            value={text}
            onChange={handleTextChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                background: '#FFF',
                '& fieldset': {
                  border: '1px solid #DAD4F7',
                },
                '&:hover fieldset': {
                  border: '1px solid #DAD4F7',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #DAD4F7',
                },
              },
              '& .MuiInputBase-input': {
                color: 'black',
                padding: '24px',
                fontSize: '14px',
              },
              flex: 1, // 高さを残りのスペースに広げる
              alignSelf: 'stretch', // コンテナにフィットさせる
              marginTop: '16px', // ファイル名部分との隙間
              height: '720px'
            }}
          />
        </Box>
      )}
    </Box>
  );
});

export default FileUpload;
