import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { PersonAddAlt1 } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';

interface DashboardPanelProps {
    onReviewHistory: () => void;
    onGroupUsers: () => void;
    onLogout: () => void;
}

const DashboardPanel: React.FC<DashboardPanelProps> = ({
    onReviewHistory,
    onGroupUsers,
    onLogout
}) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '48px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '20px',
                padding: '32px',
                borderRadius: '6px',
                border: '1px solid #DAD4F7',
                backgroundColor: '#E5E3FA',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
                zIndex: 1,
                minWidth: '220px',             // パネルの最低幅を設定
            }}
        >
            {/* 審査履歴ボタン */}
            <Button
                onClick={onReviewHistory}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#232323',
                    flexDirection: 'row',  // アイコンとテキストを横並びにする
                    whiteSpace: 'nowrap',  // テキストの改行を防ぐ
                }}
            >
                <PersonIcon />
                <Typography
                    variant="body1"
                    sx={{
                        color: '#232323',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '19.6px',  // 指定されたline-height
                    }}
                >
                    審査履歴
                </Typography>
            </Button>

            <Divider sx={{ alignSelf: 'stretch', height: 0, borderColor: '#C2B8F2', borderWidth: '1px' }} />

            {/* グループユーザーリストボタン */}
            <Button
                onClick={onGroupUsers}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#232323',
                    flexDirection: 'row',  // アイコンとテキストを横並びにする
                    whiteSpace: 'nowrap',  // テキストの改行を防ぐ
                }}
            >
                <GroupIcon />
                <Typography
                    variant="body1"
                    sx={{
                        color: '#232323',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '19.6px',  // 指定されたline-height
                    }}
                >
                    グループユーザーリスト
                </Typography>
            </Button>

            <Divider sx={{ alignSelf: 'stretch', height: 0, borderColor: '#C2B8F2', borderWidth: '1px' }} />

            {/* ログアウトボタン */}
            <Button
                onClick={onLogout}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#232323',
                    flexDirection: 'row',  // アイコンとテキストを横並びにする
                    whiteSpace: 'nowrap',  // テキストの改行を防ぐ
                }}
            >
                <LogoutIcon />
                <Typography
                    variant="body1"
                    sx={{
                        color: '#232323',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '19.6px',  // 指定されたline-height
                    }}
                >
                    ログアウト
                </Typography>
            </Button>
        </Box>
    );
};

export default DashboardPanel;
