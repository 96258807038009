import React, { useState } from 'react';
import { IconButton } from '@mui/material';

interface AddCircleButtonProps {
  onClick: () => void;
}

const AddCircleButton: React.FC<AddCircleButtonProps> = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <IconButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        backgroundColor: '#FFFFFF', // デフォルトの白い背景
        '&:hover': {
          backgroundColor: '#E5E3FA', // ホバー時の背景色
          boxShadow: 'none', // ホバー時に影がつかないように設定
        },
        padding: 0, // アイコンのサイズに合わせるため余白を除去
        width: '48px', // TextFieldの高さに近いサイズを設定
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
      }}
      onClick={onClick}
    >
      <svg
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            opacity: isHovered ? 0.7 : 1, // ホバー時に画像を薄くする（opacityを設定）
            transition: 'opacity 0.3s', // スムーズなアニメーション効果を追加
          }}
      >
        <rect
          x="0.5"
          y="1"
          width="39"
          height="39"
          rx="19.5"
          fill="white"
          stroke={isHovered ? '#8471E6' : '#5F46DE'} // ホバー時に枠線の色を変更
        />
        <path
          d="M21 19.5V14.5H19V19.5H14V21.5H19V26.5H21V21.5H26V19.5H21Z"
          fill={isHovered ? '#8471E6' : '#5F46DE'} // ホバー時にプラス記号の色を変更
        />
      </svg>
    </IconButton>
  );
};

export default AddCircleButton;
