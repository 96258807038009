import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface FileUploadHeaderProps {
  title: string;
  icon?: ReactNode;
}

const FileUploadHeader: React.FC<FileUploadHeaderProps> = ({ title, icon }) => (
  <Box
    sx={{
      display: 'flex',
      height: '64px',
      alignItems: 'center',
      flexDirection: 'row',
      gap: '16px',
      borderBottom: '2px solid #DAD4F7',
      marginBottom: '16px',
    }}
  >
    {/* アイコン */}
    {icon && (
      <Box
        sx={{
          width: '24px',
          height: '24px',
        }}
      >
        {icon}
      </Box>
    )}

    {/* タイトル */}
    <Typography
      sx={{
        color: '#232323',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '33.6px',
      }}
    >
      {title}
    </Typography>

    {/* 説明文 */}
    <Typography
      sx={{
        color: '#646464',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '140%',
      }}
    >
      必要に応じて入力内容を変更してください
    </Typography>
  </Box>
);

export default FileUploadHeader;
