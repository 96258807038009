// UserListModal.tsx
import React from 'react';
import { Box, Typography, Button, List, ListItem, Modal } from '@mui/material';

interface UserListModalProps {
  open: boolean;
  onClose: () => void;
  userList: Array<{ name: string; email: string; usage_count: number; total_cost: number }>;
}

const UserListModal: React.FC<UserListModalProps> = ({ open, onClose, userList}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          maxWidth: 600,
          margin: 'auto',
          padding: 4,
          backgroundColor: '#fff',
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
          marginTop: '10vh',
          marginBottom: '10vh',
        }}
      >
        <Typography variant="h6" gutterBottom>グループユーザリスト</Typography>
          <Box>
            <List>
            {userList.map((user, index) => (
              <ListItem key={index}>
                <Typography>
                  名前: {user.name} <br />
                  メール: {user.email} <br />
                  使用回数: {user.usage_count}回 <br />
                  合計コスト: ${user.total_cost}
                </Typography>
              </ListItem>
            ))}
            </List>
            <Button onClick={onClose} style={{ marginTop: '16px' }}>
              閉じる
            </Button>
          </Box>
      </Box>
    </Modal>
  );
};

export default UserListModal;
