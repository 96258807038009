import React, { useState } from 'react';
import {
  Box,
  Button,
} from '@mui/material';
// import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { pdf } from '@react-pdf/renderer';
import listCheck from '../assets/list-check.svg';
import shield1 from '../assets/shield-exclamation1.svg';
import shield2 from '../assets/shield-exclamation2.svg';
import ResultAccordion from './ResultAccordion';
import ResultPdf from './ResultPdf';
import { saveAs } from 'file-saver';

interface ResultDisplayProps {
  result: any;
  sortOrder: string;
  openItemIds: string[];
  displayContradictThreshold: number;
  contradictThreshold: number;
  onSortOrderChange: (value: string) => void;
  onToggle: (itemId: string) => void;
  onContradictThresholdChange: (event: Event, newValue: number | number[]) => void;
  sortQuestions: (questions: any[], order: string) => any[];
}

const ResultDisplay: React.FC<ResultDisplayProps> = ({
  result,
  sortOrder,
  openItemIds,
  displayContradictThreshold,
  contradictThreshold,
  onSortOrderChange,
  onToggle,
  onContradictThresholdChange,
  sortQuestions,
}) => {
  const [hasError, setHasError] = useState<boolean>(false);

  const handleGeneratePdf = async () => {
    try {
      const document = <ResultPdf result={result} />;
      const blob = await pdf(document).toBlob();
      saveAs(blob, `${result.file_name_1}_審査結果_${result.task_id}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setHasError(true);
    }
  };

  return (
    <Box>
      <Box mt={2} sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGeneratePdf}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            padding: '4px 8px',
            border: "1px solid #5F46DE",
            borderRadius: "4px",
            color: "#5F46DE",
            background: '#FFF',
            '&:hover': {
              background: '#E5E3FA',
            },
            '&:active': {
              background: '#DAD4F7'
            }
          }}
        >
          PDFをダウンロード
        </Button>
      </Box>

      {hasError && (
        <Box mt={2} sx={{ color: 'red' }}>
          PDFの生成中にエラーが発生しました。再度お試しください。
        </Box>
      )}


      <ResultAccordion
        displayName="審査結果"
        displayIcon={listCheck}
        resultList={result.exam_result}
        sortOrder={sortOrder}
        openItemIds={openItemIds}
        onToggle={onToggle}
        sortQuestions={sortQuestions}
      />
      <ResultAccordion
        displayName="研究計画書上で一貫性が欠けている箇所"
        displayIcon={shield1}
        resultList={result.contradictions}
        sortOrder={sortOrder}
        openItemIds={openItemIds}
        onToggle={onToggle}
        sortQuestions={sortQuestions}
      />
      {result.cross_contradictions.length > 0 && Array.isArray(result.cross_contradictions) && (
        <ResultAccordion
          displayName="研究計画書と同意書間で一貫性が欠けている箇所"
          displayIcon={shield2}
          resultList={result.cross_contradictions}
          sortOrder={sortOrder}
          openItemIds={openItemIds}
          onToggle={onToggle}
          sortQuestions={sortQuestions}
        />
      )}

      {/* <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        bgcolor="#f5f5f5"
        padding="16px"
        borderRadius="8px"
        marginBottom="16px"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            使用料金(審査):
          </Typography>
          <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
            ${result.exam_cost.toFixed(4)} USD
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%" marginTop="8px">
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            使用料金(計画書上の矛盾発見):
          </Typography>
          <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
            ${result.contradict_cost.toFixed(4)} USD
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%" marginTop="8px">
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            使用料金(計画書と同意書の間の矛盾発見):
          </Typography>
          <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
            ${result.cross_contradict_cost.toFixed(4)} USD
          </Typography>
        </Box>
      </Box> */}

      {/* 閾値設定セクション */}
      {/* <Box marginY={2}>
        <Typography variant="h6">一貫性が欠けている疑いがある箇所の表示閾値</Typography>
        <Slider
          value={contradictThreshold}
          min={0.0}
          max={1.0}
          step={0.1}
          onChange={onContradictThresholdChange}
          valueLabelDisplay="auto"
          aria-labelledby="threshold-slider"
          style={{ width: '200px' }}
        />
        <Typography variant="body2">
          矛盾スコアが {displayContradictThreshold.toFixed(1)} 以上の項目のみが表示されます。
        </Typography>
      </Box> */}

    </Box>
  );
};

export default ResultDisplay;
