import React from 'react';

interface AddIconProps {
  color: string;
  width?: number;
  height?: number;
}

const AddIcon: React.FC<AddIconProps> = ({ color, width = 20, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path d="M10.8333 9.16667V5H9.16667V9.16667H5V10.8333H9.16667V15H10.8333V10.8333H15V9.16667H10.8333Z" fill={color} />
  </svg>
);

export default AddIcon