import { Grid, Box } from '@mui/material';
import FileUpload from './FileUpload';
import { RefObject } from 'react';
import consentFileIcon from '../assets/consentFileIcon.svg'
import researchPlanIcon from '../assets/researchPlanIcon.svg'

interface DocumentUploadSectionProps {
  researchPlanRef: RefObject<{ getText: () => string; getFileName: () => string; setTextField: (text: string) => void }>;
  consentFormRef: RefObject<{ getText: () => string; getFileName: () => string; setTextField: (text: string) => void }>;
  handleUpload: (file: File, setText: (text: string) => void) => Promise<void>;
}

const DocumentUploadSection: React.FC<DocumentUploadSectionProps> = ({ researchPlanRef, consentFormRef, handleUpload }) => (
  <Box>
    <Grid container spacing={5} sx={{ marginTop: '0px' }}>
      <Grid item xs={6}>
        <FileUpload
          ref={researchPlanRef}
          title="研究計画書"
          // label="ここに研究計画書を入力してください"
          // placeholder="ここに研究計画書を入力してください"
          acceptedFileTypes=".doc,.docx"
          onUpload={handleUpload}
          icon={<img src={researchPlanIcon} alt="研究計画書のアイコン"/>} // アイコンを受け取る部分（後で定義）
        />
      </Grid>
      <Grid item xs={6}>
        <FileUpload
          ref={consentFormRef}
          title="説明同意文書"
          // label="ここに同意書を入力してください"
          // placeholder="ここに同意書を入力してください"
          acceptedFileTypes=".doc,.docx"
          onUpload={handleUpload}
          icon={<img src={consentFileIcon} alt="同意書のアイコン"/>} // アイコンを受け取る部分（後で定義）
        />
      </Grid>
    </Grid>
  </Box>
);

export default DocumentUploadSection;
