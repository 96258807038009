import { useState, useEffect, useRef } from 'react';
import { doc, onSnapshot, DocumentData, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';  // Import db from your existing firebase.js

// Custom hook to monitor task status
const useTaskStatus = (
    taskId: string | null,
    onComplete: (taskId: string) => Promise<void>,
    onError: (errorMessage: string) => void
) => {
    // Set status state, default to 'pending'
  const [status, setStatus] = useState<string>('pending');
  const hasCompletedRef = useRef<boolean>(false);
  const timeoutSecond = 120;

  useEffect(() => {
    if (!taskId) return;
    
    // Reset the hasCompleted flag and status when taskId changes
    hasCompletedRef.current = false;
    setStatus('pending');
    console.log(`Resetting state for new taskId: ${taskId}`);

    const taskRef = doc(db, 'tasks', taskId);

    // Set up a real-time listener to monitor task status
    const unsubscribe = onSnapshot(taskRef, (doc: DocumentData) => {
      if (doc.exists()) {
        const taskStatus = doc.data().status;
        setStatus(taskStatus);
        console.log(`Monitoring task status of ${taskId}: ${taskStatus} with hasCompleted=${hasCompletedRef.current}`);

        // If status is 'complete', invoke the callback function to make the API request
        if (taskStatus === 'complete' && !hasCompletedRef.current) {
            hasCompletedRef.current = true;
            clearTimeout(timeout);
            unsubscribe();
            onComplete(taskId);
            deleteDoc(taskRef).then(() => {
              console.log(`Document with taskId ${taskId} deleted successfully.`);
            }).catch((error) => {
              console.error(`Error deleting document with taskId ${taskId}:`, error);
            });
            console.log('onComplete');
        } else if (taskStatus === 'errored out' && !hasCompletedRef.current) {
            hasCompletedRef.current = true;
            clearTimeout(timeout);
            unsubscribe(); 
            onError('エラーが発生しました');
            deleteDoc(taskRef).then(() => {
            console.log(`Document with taskId ${taskId} deleted successfully.`);
            }).catch((error) => {
                console.error(`Error deleting document with taskId ${taskId}:`, error);
            });
            console.log('onError');
        }
      } else {
        console.log('No such document!');
      }
    });

    // Set the timeout to stop monitoring if it takes too long
    const timeout = setTimeout(() => {
        if (!hasCompletedRef.current) {
          setStatus('timeout');
          unsubscribe();
          onError('タイムアウトエラーが発生しました');
          deleteDoc(taskRef).then(() => {
            console.log(`Document with taskId ${taskId} deleted successfully.`);
          }).catch((error) => {
            console.error(`Error deleting document with taskId ${taskId}:`, error);
          });
          console.log('onError due to timeout');
        }
      }, timeoutSecond * 1000);

    // Clean up the listener when the component is unmounted
    return () => {
        unsubscribe();
        clearTimeout(timeout);
        console.log(`Cleanup for taskId: ${taskId}`);
    };
  }, [taskId, onComplete, onError]);

  return status;  // Return the current status
}

export default useTaskStatus;