import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import logoBase64 from '../assets/logoBase64';
import { getColorForScore, getBorderForScore, getTextColorForScore } from '../utils/resultUtils';

// ワークスペース内のフォントファイルを登録
Font.register({
  family: 'Albert Sans',
  src: `${process.env.PUBLIC_URL}/fonts/AlbertSans-Regular.ttf`,
  onError: (error: string) => {
    console.error('Error registering Albert Sans font:', error);
  }
});

Font.register({
  family: 'Noto Sans',
  src: `${process.env.PUBLIC_URL}/fonts/NotoSansJP-Regular.ttf`,
  onError: (error: string) => {
    console.error('Error registering Noto Sans font:', error);
  }
});

// スタイルシートにフォントを適用
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Noto Sans', // 日本語用のフォントをデフォルトに設定
    backgroundColor: '#FFFFFF',
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: '#DAD4F7',
    paddingBottom: 8,
    marginBottom: 8,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: '#232323',
    marginLeft: 8,
  },
  listItem: {
    marginBottom: 8,
  },
  badge: {
    backgroundColor: '#CECECE',
    borderRadius: 8,
    width: 36,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
    fontSize: 14,
    fontWeight: 700,
    color: '#000000',
  },
  checklistText: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#646464',
    marginBottom: 4,
  },
  checklistItem: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#646464',
    lineHeight: 1.4,
  },
  detailsBox: {
    marginLeft: 10,
    padding: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DAD4F7',
    borderRadius: 8,
    marginBottom: 8,
  },
  detailsTitle: {
    fontSize: 12,
    color: '#7762e3',
    fontWeight: 'bold',
    marginBottom: 2,
  },
  detailsContent: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#232323',
    marginBottom: 4,
  },
  fileInfo: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4,
    color: '#232323',
  },
  logo: {
    width: 99.556,    // ロゴの幅を設定
    height: 28,    // ロゴの高さを設定
    marginBottom: 1,
  },
});

interface ResultPdfProps {
  result: any;
}

const ResultPdf: React.FC<ResultPdfProps> = ({ result }) => {
//   console.log('Rendering ResultPdf component with result:', result); // 渡されたresultの確認

  if (!result || typeof result !== 'object') {
    console.error('Error: result is null or undefined');
    return (
      <Document>
        <Page style={styles.page}>
          <Text>エラー: データがありません。</Text>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page style={styles.page}>
        <Image src={logoBase64} style={styles.logo} />
        <Text style={{ fontSize: 9 }}>Borzoi AI株式会社</Text>

        <Text style={{ textAlign: 'center', fontSize: 20, marginBottom: 20 }}>結果概要</Text>
        {result.file_name_1 && <Text style={styles.fileInfo}>ファイル名: {result.file_name_1}</Text>}
        {result.file_name_2 && <Text style={styles.fileInfo}>同意書ファイル名: {result.file_name_2}</Text>}
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionTitle}>審査結果</Text>
        </View>
        {Array.isArray(result.exam_result) && result.exam_result.map((item: any, index: number) => {
          const score = item.result?.score;

          if (item.checklist_item === null) {
            console.log("exam_result checklist_item");
          }
          if (item.result.score === null) {
            console.log("exam_result score");
          }
          if (item.result.section === null) {
            console.log("exam_result section");
          }
          if (item.result.reason === null) {
            console.log("exam_result reason");
          }
          if (item.result.recommendation === null) {
            console.log("exam_result recommendation");
          }

          return (
            <View key={item.id || index} style={styles.listItem}>
              <View
                style={{
                  ...styles.badge,
                  backgroundColor: getColorForScore(score),
                  border: getBorderForScore(score),
              }}>
                <Text style={{ color: getTextColorForScore(score) }}>
                  {score != null ? score.toFixed(1) : 'N/A'}
                </Text>
              </View>

              <View style={styles.checklistText}>
                <Text>
                  {`${index + 1}. ${item.checklist_item || '項目がありません'}`}
                </Text>
              </View>

              <View style={styles.detailsBox}>
                <Text style={styles.detailsTitle}>該当箇所</Text>
                <Text style={styles.detailsContent}>
                  {item.result && item.result.section ? item.result.section : 'データがありません'}
                </Text>
                <Text style={styles.detailsTitle}>理由</Text>
                <Text style={styles.detailsContent}>
                  {item.result && item.result.reason ? item.result.reason : 'データがありません'}
                </Text>
                <Text style={styles.detailsTitle}>改善案</Text>
                <Text style={styles.detailsContent}>
                  {item.result && item.result.recommendation ? item.result.recommendation : 'データがありません'}
                </Text>
              </View>
            </View>
          );
        })}

        {/* 研究計画書上で一貫性が欠けている箇所セクション */}
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionTitle}>研究計画書上で一貫性が欠けている箇所</Text>
        </View>
        {Array.isArray(result.contradictions) && result.contradictions.map((item: any, index: number) => {
          const score = item.result?.score;

          if (item.checklist_item === null) {
            console.log("contradiction checklist_item");
          }
          if (item.result.score === null) {
            console.log("contradiction score");
          }
          if (item.result.section_1 === null) {
            console.log("contradiction section_1");
          }
          if (item.result.section_2 === null) {
            console.log("contradiction section_2");
          }
          if (item.result.reason === null) {
            console.log("contradiction reason");
          }
          if (item.result.recommendation === null) {
            console.log("contradiction recommendation");
          }

          return (
            <View key={index} style={styles.listItem}>
              <View
                style={{
                  ...styles.badge,
                  backgroundColor: getColorForScore(score),
                  border: getBorderForScore(score),
              }}>
                <Text style={{ color: getTextColorForScore(score) }}>
                  {score != null ? score.toFixed(1) : 'N/A'}
                </Text>
              </View>

              <View style={styles.checklistText}>
                <Text>
                  {`${index + 1}. ${item.checklist_item || '項目がありません'}`}
                </Text>
              </View>

              <View style={styles.detailsBox}>
                <Text style={styles.detailsTitle}>該当箇所1</Text>
                <Text style={styles.detailsContent}>
                  {item.result && item.result.section_1 ? item.result.section_1 : 'データがありません'}
                </Text>
                <Text style={styles.detailsTitle}>該当箇所2</Text>
                <Text style={styles.detailsContent}>
                  {item.result && item.result.section_2 ? item.result.section_2 : 'データがありません'}
                </Text>
                <Text style={styles.detailsTitle}>理由</Text>
                <Text style={styles.detailsContent}>
                  {item.result && item.result.reason ? item.result.reason : 'データがありません'}
                </Text>
                <Text style={styles.detailsTitle}>改善案</Text>
                <Text style={styles.detailsContent}>
                  {item.result && item.result.recommendation ? item.result.recommendation : 'データがありません'}
                </Text>
              </View>
            </View>
          );
        })}

        {/* 研究計画書と同意書間で一貫性が欠けている箇所セクション */}
        {result.cross_contradictions && result.cross_contradictions.length > 0 && Array.isArray(result.cross_contradictions) && (
          <>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionTitle}>研究計画書と同意書間で一貫性が欠けている箇所</Text>
            </View>
            {result.cross_contradictions.map((item: any, index: number) => {
              const score = item.result?.score;

              if (item.checklist_item === null) {
                console.log("cross_contradiction checklist_item");
              }
              if (item.result.score === null) {
                console.log("cross_contradiction score");
              }
              if (item.result.section_1 === null) {
                console.log("cross_contradiction section_1");
              }
              if (item.result.section_2 === null) {
                console.log("cross_contradiction section_2");
              }
              if (item.result.reason === null) {
                console.log("cross_contradiction reason");
              }
              if (item.result.recommendation === null) {
                console.log("cross_contradiction recommendation");
              }

              return (
                <View key={index} style={styles.listItem}>
                  <View
                    style={{
                      ...styles.badge,
                      backgroundColor: getColorForScore(score),
                      border: getBorderForScore(score),
                  }}>
                    <Text style={{ color: getTextColorForScore(score) }}>
                      {score != null ? score.toFixed(1) : 'N/A'}
                    </Text>
                  </View>

                  <View style={styles.checklistText}>
                    <Text>
                      {`${index + 1}. ${item.checklist_item || '項目がありません'}`}
                    </Text>
                  </View>

                  <View style={styles.detailsBox}>
                    <Text style={styles.detailsTitle}>該当箇所1</Text>
                    <Text style={styles.detailsContent}>
                      {item.result && item.result.section_1 ? item.result.section_1 : 'データがありません'}
                    </Text>
                      <Text style={styles.detailsTitle}>該当箇所2</Text>
                      <Text style={styles.detailsContent}>
                        {item.result && item.result.section_2 ? item.result.section_2 : 'データがありません'}
                      </Text>
                      <Text style={styles.detailsTitle}>理由</Text>
                      <Text style={styles.detailsContent}>
                        {item.result && item.result.reason ? item.result.reason : 'データがありません'}
                      </Text>
                      <Text style={styles.detailsTitle}>改善案</Text>
                      <Text style={styles.detailsContent}>
                        {item.result && item.result.recommendation ? item.result.recommendation : 'データがありません'}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </>
          )}
        </Page>
    </Document>
  );
};

export default ResultPdf;
