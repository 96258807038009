import React from 'react';
import {
  Box,
  // AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Badge,
  Typography,
  ListItemText,
  Collapse,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { getColorForScore, getBorderForScore, getTextColorForScore } from '../utils/resultUtils';

const getContradictionId = (type: string, index: number) => `${type}-${index}`;

const roundWithScale = (value: number, scale: number) => {
  return Math.round(value * 10 ** scale) / 10 ** scale;
};


interface ResultAccordionProps {
  displayName: string;
  displayIcon: string;
  resultList: any;
  sortOrder: string;
  openItemIds: string[];
  onToggle: (id: string) => void;
  sortQuestions: (results: any[], order: string) => any[];
}

const ResultAccordion: React.FC<ResultAccordionProps> = ({
  displayName,
  displayIcon,
  resultList,
  sortOrder,
  openItemIds,
  onToggle,
  sortQuestions,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          height: '64px',
          width: "100%",
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
          borderBottom: '2px solid #DAD4F7'
        }}
      >
        {/* アイコン */}
        <Box sx={{ width: '24px', height: '24px' }}>
          <img src={displayIcon} alt="displayIcon" />
        </Box>

        {/* タイトル */}
        <Typography
          sx={{
            color: '#232323',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '33.6px',
          }}
        >
          {displayName}
        </Typography>
      </Box>

      <AccordionDetails sx={{ paddingLeft: '0px'}}>
        <List>
          {sortQuestions(resultList, sortOrder).map((item: any, index: number) => (
            <Box key={item.id || index} sx={{marginBottom: '16px'}}>
              <ListItem key={index} onClick={() => onToggle(getContradictionId(displayName, index))} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                {/* スコアバッジ */}
                <Badge
                    sx={{
                        backgroundColor: getColorForScore(item.result.score),
                        border: getBorderForScore(item.result.score),
                        borderRadius: '8px',
                        width: '64px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '16px',
                        minWidth: '60px'
                    }}
                >
                  <Typography
                    sx={{
                        fontSize: "28px",
                        fontWeight: '700',
                        color: getTextColorForScore(item.result.score),
                        padding: '4px'
                    }}
                  >
                    {roundWithScale(item.result.score, 1).toFixed(1)}
                  </Typography>
                </Badge>

                {/* チェックリストアイテム */}
                <Box sx={{display:"flex", alignItems:"center"}}>
                  <ListItemText primary={index + 1} sx={{ fontSize: "20px", fontWeight: '700', fontFamily: "Albert Sans", marginRight: '8px', color: "#646464", lineHeight: '100%'}} />
                  <ListItemText primary={item.checklist_item} sx={{ fontSize: "15px", fontWeight: '500', color: "#646464", lineHeight: '140%' }} />
                </Box>

                {openItemIds.includes(getContradictionId(displayName, index)) ? (
                  <ExpandLess sx={{ marginLeft: "auto" }} />
                ) : (
                  <ExpandMore sx={{ marginLeft: "auto" }} />
                )}
              </ListItem>

              {/* チェックリストアイテムの詳細 */}
              <Collapse in={openItemIds.includes(getContradictionId(displayName, index))} timeout="auto" unmountOnExit>
                {displayName === "審査結果" && (
                  <Box sx={{marginLeft:10, backgroundColor: '#ffffff', padding: "24px", textAlign: "left", border: "1px solid #dad4f7", borderRadius: "8px" }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography sx={{ color: "#7762e3", textAlign: "left", fontSize: '14px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>該当箇所</Typography>
                      <Typography sx={{ textAlign: "left", marginBottom: "8px", fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: "140%" }}>
                        {item.result.section}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography sx={{ color: "#7762e3", textAlign: "left", fontSize: '14px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>理由</Typography>
                      <Typography sx={{ textAlign: "left", marginBottom: "8px", fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>
                        {item.result.reason}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography sx={{ color: "#7762e3", textAlign: "left", fontSize: '14px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>改善案</Typography>
                        <Typography sx={{ textAlign: "left", marginBottom: "8px", fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>
                          {item.result.recommendation}
                        </Typography>
                    </Box>
                  </Box>
                )}

                {(displayName === "研究計画書上で一貫性が欠けている箇所" || displayName === "研究計画書と同意書間で一貫性が欠けている箇所") && (
                  <Box sx={{marginLeft:10, marginY:2, backgroundColor: '#ffffff', padding: "24px", textAlign: "left", border: "1px solid #dad4f7", borderRadius: "8px"}}>
                    <Box display="flex" justifyContent="space-between">
                      <Box sx={{flex:1, marginRight:2}}>
                        <Box sx={{display: 'inline-block', padding:"2px 4px", backgroundColor: '#f3f1fc', borderRadius:"4px"}} >
                          <Typography sx={{ color: '#5f46de', textAlign: 'left', fontSize: '14px', fontStyle: 'normal', fontWeight: '700', lineHeight: '140%' }}>
                            該当箇所1
                          </Typography>
                        </Box>
                        <Typography sx={{ marginTop: '8px', textAlign: 'left', fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>
                          {item.result.section_1}
                        </Typography>
                      </Box>
                      <Box sx={{flex:1, marginLeft:2}}>
                        <Box sx={{display: 'inline-block', padding:"2px 4px", backgroundColor: '#f3f1fc', borderRadius:"4px"}} >
                          <Typography sx={{ color: '#5f46de', textAlign: 'left', fontSize: '14px', fontStyle: 'normal', fontWeight: '700', lineHeight: '140%' }}>
                            該当箇所2
                          </Typography>
                        </Box>
                          <Typography sx={{ marginTop: '8px', textAlign: 'left', fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>
                            {item.result.section_2}
                          </Typography>
                        </Box>
                      </Box>
                      <Box marginTop={2}>
                        <Typography sx={{ color: '#5f46de', textAlign: 'left', fontSize: '14px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>
                          理由
                        </Typography>
                        <Typography sx={{ marginTop: '8px', textAlign: 'left', fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>
                          {item.result.reason}
                        </Typography>
                      </Box>
                      <Box marginTop={2}>
                        <Typography sx={{ color: '#5f46de', textAlign: 'left', fontSize: '14px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>
                            改善案
                        </Typography>
                        <Typography sx={{ marginTop: '8px', textAlign: 'left', fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: '140%' }}>
                            {item.result.recommendation}
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </Collapse>
            </Box>
          ))}
        </List>
      </AccordionDetails>
    </Box>
  );
};

export default ResultAccordion;
