import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import logo from '../assets/logo.svg';

const Footer: React.FC = () => {
    return (
        <Box
            component="footer"
            sx={{
                width: '100vw',             // ビューポート全体の幅を指定
                backgroundColor: '#FFF',    // 背景色を全体に適用
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    margin: '0 auto',         // 中央揃え
                    padding: '24px 80px',     // パディングを適用
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                {/* Left side (Logo and company info) */}
                <Box sx={{ textAlign: 'left' }}>
                    {/* Logo */}
                    <Box sx={{ flexShrink: 0 }}>
                        <img src={logo} alt="Logo" style={{ width: '85.333px', height: '24px' }} />
                    </Box>
                    <Typography variant="body2" sx={{ color: '#232323', marginTop: '16px', fontSize: '15px', fontWeight: '500' }}>
                        Borzoi AI 株式会社
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#232323', fontSize: '13px', fontWeight: '500' }}>
                        〒101-0064<br />
                        東京都千代田区神田猿楽町2-8-11 VORT水道橋Ⅲ<br /><br />
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#232323', fontSize: '13px', fontWeight: '400' }}>
                        ©︎Borzoi AI Inc.
                    </Typography>
                </Box>

                {/* Right side (Terms and Privacy Links) */}
                <Box
                    sx={{
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '40px',
                        alignSelf: 'flex-end',  // 左側の4行目に合わせて、右側のリンクを下に揃える
                    }}
                >
                    <Link href="#" underline="none" sx={{ color: '#232323', fontSize: '13px', fontWeight: '500' }}>
                        利用規約
                    </Link>
                    <Link href="#" underline="none" sx={{ color: '#232323', fontSize: '13px', fontWeight: '500' }}>
                        プライバシーポリシー
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
